import React from 'react';
import { useTranslation } from 'react-i18next';

const CtaV13 = () => {
  let publicUrl = process.env.PUBLIC_URL+'/'
  let imagealt = 'image'
  const { t } = useTranslation();
    return <section className="cta-five  go-top cta-v13">
              <div className="container">
                <div className="row">
                <div className="col-lg-5 col-12 ">
                  
                    <div className="cta-five__video cta-video-mobile-3 pr-4 ">
                    <video width="100%" height="500"  loop autoPlay muted>
      <source src={publicUrl+"assets/videos/video3portada.mp4"} type="video/mp4"/>
</video>
                    </div>{/* /.cta-five__image */}
                  </div>{/* /.col-lg-7 */}
                 
                    
                  <div className="col-lg-2 col- d-none ">
                  <div className="cta-v13__image">
                      <img src={publicUrl+"assets/images/resources/termometro.png"} alt={ imagealt } width="300"/>
                    
                    </div>{/* /.cta-five__image */}
                  </div>{/* /.col-lg-7 */}
                  <div className="col-lg-7 d-flex">
                    <div className="my-auto">
                      <div className="cta-five__content">
                        <h3>{t('home.section2.title')}</h3>
                        <p>{t('home.section2.subtitle')}</p>
                        <div className="cta-four__btn-block">
                          <a href="/service/" className="thm-btn">{t('home.section2.button')}</a>
                         
                        </div>{/* /.cta-four__btn-block */}
                      </div>{/* /.cta-five__content */}
                    </div>{/* /.my-auto */}
                  </div>{/* /.col-lg-5 */}
                 
                  
                </div>{/* /.row */}
              </div>{/* /.container */}
            </section>

        
	}

export default CtaV13