import React from 'react';
import { useTranslation } from 'react-i18next';

const CtaV15 = () => {
  let publicUrl = process.env.PUBLIC_URL+'/'
  let imagealt = 'image'
  const { t } = useTranslation();

    return <section className="cta-five  go-top cta-v13">
              <div className="container">
                <div className="row">
     
                 
                    
                  <div className="col-lg-5">
                  <div className="cta-v13__image pl-5 pt-5 pb-5 text-center">
                      
                      <img src={publicUrl+"assets/images/resources/making-of-video-corporatiu-2023.jpg"} alt={ imagealt } width="500" />
                    </div>{/* /.cta-five__image */}
                  </div>{/* /.col-lg-7 */}
                  <div className="col-lg-7 d-flex">
                    <div className="my-auto">
                      <div className="cta-five__content cta15-contenido">
                        <h3>{t('home.section4.title')}</h3>
                        <p>{t('home.section4.subtitle')}</p>
                        
                      </div>{/* /.cta-five__content */}
                    </div>{/* /.my-auto */}
                  </div>{/* /.col-lg-5 */}
                 
                  
                </div>{/* /.row */}
              </div>{/* /.container */}
            </section>

        
	}

export default CtaV15