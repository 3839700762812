import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Certificate = (props) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setResponse(null);
    setError(null);
  };

  const handleTextUpload = () => {
    if (inputValue) {
      // Realizar una solicitud al backend
      fetch(`https://admin.simoonsvirtual.cat/api/sessions/activity/certificate/validate/${inputValue}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error('Error de validación');
          }
        })
        .then((data) => {
          setResponse(data);
          setError(null);
        })
        .catch((error) => {
          // Manejar errores de validación
          setResponse(null);
          setError('Error al validar el certificado. Inténtalo de nuevo.');
          console.error(error);
        });
    }
  };

  return (
    <section className="certificado">
      <div className="container-fluid">
        <div className="row cta-certificado text-center">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="funfact-one__single">
              <h3 className="text-light cta-titulo">{t('home.certificate.title')}</h3>
             
              <input
                className="form_certificado"
                type="text"
                placeholder={t('home.certificate.formtext')}
                value={inputValue}
                onChange={handleInputChange}
              /> 
              <button className="thm-btn btn-cerificado" onClick={handleTextUpload}>
                {t('home.certificate.button')}
              </button>
              {error && (
                <div className="alert alert-danger mt-3" role="alert">
                  <p>{error}</p>
                </div>
              )}
              {response && response.name && (
                <div className="alert alert-success mt-3 success-message" role="alert">
                  <p className='pb-4'>{t('home.certificate.nameUser')} {response.name}</p>
                  <p>{t('home.certificate.nsesions')} {response.number_of_sessions}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Certificate;
