import React from 'react';
import { useTranslation } from 'react-i18next';

const TeamPage = () => {

	const { t } = useTranslation();

        let publicUrl = process.env.PUBLIC_URL+'/' 

    return <section className="team-one team-one__team-page instituciones">
			  <div className="container mt-5 pt-5 ">
			    <div className="team-one__top ">
			      <div className="row mb-5">
			        <div className="col-md-12">
			          <div className="block-title-two text-center pb-4">
			            
			            <h3>{t('team.title')}</h3>
			          </div>
			        </div>{/* /.col-md-6 */}
			        <div className="col-md-12 text-center" >
			          <div className="my-auto">
			            <div className="team-one__top-text">
			              <p>
							{t('team.subtitle')}</p>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
				</div>
				<div className="container-fluid p-5 instituciones">
				
				 <div className="row justify-content-center">
				 
					<div className="col-lg-4 col-md-6 box-inst-left d-none d-sm-block circulos-team" >
						<div className='circulo-lt '></div>			        
					</div>{/* /.col-lg-3 */}
				  	<div className="col-lg-4 col-md-6 text-center box-instituciones" >
					  <a href='https://www.vallhebron.com/es' target="_blank" className='enlace-instituciones'>
						<h4>{t('team.team1')}</h4>
						<img className="logo-team" src={publicUrl + "assets/images/brand/logo_vall_blue.png"}   />
						</a>
					  </div>{/* /.col-lg-3 */}
					<div className="col-lg-4 col-md-6 d-none d-sm-block circulos-team" >
			         	<div className='circulo-tr'></div>
						 
					</div>{/* /.col-lg-3 */}				
			   
				</div> 

				<div className="row justify-content-center row-centro">
			      	<div className="col-lg-4 col-md-6  text-center box-instituciones" >
					  <a href='https://www.vallhebron.com/es/docencia/vall-dhebron-centro-de-simulacion-clinica-avanzada' target="_blank" className='enlace-instituciones'>
						<h4>{t('team.team4')}</h4>
						<img className="logo-team" src={publicUrl + "assets/images/brand/CAMPUS+VHISCA_idigital-es.png"}   />
					  </a>
			      	</div>{/* /.col-lg-3 */}
				  	<div className="col-lg-4 col-md-6 text-center " >
						<div className='rounded-circle logo-instituciones'>
							<h4>SIMOONS</h4>
						</div>
					</div>{/* /.col-lg-3 */}
					<div className="col-lg-4 col-md-6  text-center box-instituciones" >
					  <a href='https://www.psicovr.com' target="_blank" className='enlace-instituciones'>
						<h4>{t('team.team2')}</h4>
						<img className="logo-team" src={publicUrl + "assets/images/brand/PSICOVR.png"}   />
					  </a>
					</div>{/* /.col-lg-3 */}
			    </div>
				<div className="row justify-content-center">
					<div className="col-lg-4 col-md-6 box-inst-left circulos-team" >
						<div className='circulo-lb d-none d-sm-block'></div>
					</div>{/* /.col-lg-3 */}
					<div className="col-lg-4 col-md-6 text-center box-instituciones" >
					  <a href='https://vhir.vallhebron.com/es' target="_blank" className='enlace-instituciones'>
						<h4>{t('team.team3')}</h4>
						<img className="logo-team" src={publicUrl + "assets/images/brand/logo VHIR 2022-digital-RGB.png"}   />	
					  </a>
					</div>{/* /.col-lg-3 */}
					<div className="col-lg-4 col-md-6 circulos-team" >
						<div className='circulo-br d-none d-sm-block'></div>
					</div>{/* /.col-lg-3 */}
			    </div> 
			   
			  </div>
			</section>


        
}

export default TeamPage