import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Brand from './section-components/brand-one';
import WhyChooseUs from './section-components/why-choose-us';
import Service from './section-components/service';
import AboutStyleOne from './section-components/about-style-one';
import AboutStyleTwo from './section-components/about-style-two';
import Team from './section-components/team';
import Skill from './section-components/skill';
import Portfolio from './section-components/portfolio-style-one';
import Funfact from './section-components/funfact';
//import Testimonial from './section-components/testimonial-style-one';
import CTA from './section-components/cta';
import PostSlider from './section-components/post-slider';
import Footer from './global-components/footer';
import Cta from './section-components/cta-v8';
import CtaV13 from './section-components/cta-v13';
import CtaV14 from './section-components/cta-v14';
import CtaV15 from './section-components/cta-v15';
import Testimonial from './section-components/testimonial-v2';
import CtaPortada from './section-components/cta-portada';
import CtaCertificado from './section-components/cta-certificado';



const Home_V1 = (props) => {

	

    return <div>
        <Navbar />
        <Banner /> 
        <CtaPortada/>
        <Cta/>
        <CtaV13/>
        <CtaV14/>
        <CtaV15/>
        <Testimonial/>
        <CtaCertificado/>
       

        {/**  <Brand/>
         <AboutStyleOne />
        
        <WhyChooseUs />
      <Service/>
        <AboutStyleTwo />
        <Team />
        <Skill />
        <Portfolio />
        <Funfact />
        
        <CTA />
        <Brand />
        <PostSlider />*/}
        <Footer />
    </div>
}

export default Home_V1

