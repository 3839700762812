import React from 'react';
import Navbar from './global-components/navbar-color';
import PageHeader from './global-components/page-header';
import Post from './blog-components/blog-details';
import Footer from './global-components/footer';
import Blog from '../json/posts.json';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

 
const BlogList = () => {
    let {id} = useParams();
    const result = Blog.find(post => post.id == id);
    const [post, setPost] = React.useState(result)

   
    return <div>
        <Navbar />
       {/* <PageHeader headertitle={post.title}  />*/}
        <Post post={post}/>
        <Footer />
    </div>
}

export default BlogList

