import React from 'react';
import { useTranslation } from 'react-i18next';

const ServiceDetails = (props) => {

        let HeaderTitle = props.headertitle;
		let collaboratorsTitle = props.escenarios.titlecolab;
        let publicUrl = process.env.PUBLIC_URL+'/';
        let imagealt = 'image';
        const isProximamente = props.escenarios.active;
		const { t } = useTranslation();


        return (
            <>
                <section className="service-details">
                    <div className="container">
                        <div className='row pt-5'>
                            <div className='col-12 text-center pt-5'>
                                { isProximamente === false && <span className='proximamente'>En desarrollo</span>}
                                <h1 className='pt-4'>{HeaderTitle}</h1>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            {props.escenarios.highlights.map((item, i) => (
                                <div className="col-lg-4 wow fadeInUp p-4 text-center" data-wow-duration="1500ms">
                                    <div className="service-details__content">
                                        <img className='rounded' src={publicUrl+`assets/images/escenarios${item.img}`} width='100%' />
                                        <h4 className='pt-4 pr-4 pl-4'>{item.title}</h4>
                                        <p className='pb-4 pr-4 pl-4'>{item.description}</p>
                                    </div>{/* /.service-details__content */}
                                </div>
                            ))}
                        </div>{/* /.row */}
                        <div className="row">
                            {props.escenarios.colaboradores && (
                                <div className="col-lg-12">
                                    <h3 className='pb-4'>{collaboratorsTitle}</h3>
                                    <a href={`${props.escenarios.colaboradores_link}`} target="_blank">
                                        <img
                                            src={publicUrl + `assets/images/escenarios/${props.escenarios.colaboradores}`}
                                            alt={imagealt} 
                                            height="100"
                                        />
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>{/* /.container */}
                </section>
            </>
        );
    
}

export default ServiceDetails;
