import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function RegistrationForm() {
  const history = useHistory()
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(history?.location?.state?.emailRegister ?? '')
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitted, submitForm] = useState(false);
  const {t}=useTranslation();




  const handleInputChange = (e) => {
    const {id , value} = e.target;
    if(id === "firstName"){
      setFirstName(value);
    }
    if(id === "lastName"){
      setLastName(value);
    }
   
    if(id === "email"){
      setEmail(value);
    }
   
  }

  const handleSubmit  = (e) => {
    e.preventDefault();
    submitForm(true);


    if (!firstName || !lastName || !email) {
      setError('Por favor, complete todos los campos');
      return;
    }
	  
		if (!/\S+@\S+\.\S+/.test(email)) {
			setError('Ingrese un correo electrónico válido');
			return;
		}

    const url = ' https://admin.simoonsvirtual.cat/api/users';
      // Crea el objeto de datos
      const data = {
        name: firstName,
        surname: lastName,
        email: email,
        
      };

      // Realiza la solicitud POST
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
    },
      body: JSON.stringify(data),
    })
 
  .then(response => {
    if (response.status === 200) {
      setSuccessMessage(t('register.dontexist'));
      setError('');
      submitForm(false);
      return response.json();
      
    } else {
      setError(t('register.exist'));
      setSuccessMessage('');
      submitForm(false);
      throw new Error(t('register.exist'));
       // Captura el mensaje de error y asígnalo a la variable 'error'
    }
  })
   
    .catch(error=> {
      console.error('Error:', error);
    });
  }

    

  return(
    <section className="banner-one go-top">  
			<div className='overlay'>
				<div className="container-fluid">
				    <div className="row">
				      <div className="col-lg-6 offset-lg-3 text-center form-registro">
				        <div className="banner-one__content">
				          <h1 className='text-light pb-3'>{t('register.title')}</h1>
                    <form onSubmit={handleSubmit}>
                      <div className="username">
                        <input className="form__input" type="text" value={firstName|| ''} onChange = {(e) => handleInputChange(e)} id="firstName" placeholder={t('register.name')} required/>
                      </div>
                      <div className="lastname">
                        <input  type="text" name="" id="lastName" value={lastName|| ''}  className="form__input" onChange = {(e) => handleInputChange(e)} placeholder={t('register.firstname')} required/>
                      </div>
                      
                      <div className="email">
                        <input  type="email" id="email" className="form__input" value={email|| ''} onChange = {(e) => handleInputChange(e)} placeholder="Email" required/>
                      </div>
                      
                      <button  type="submit" className="thm-btn btn-register">{t('register.button')}</button>
                      {isSubmitted && <div class="spinner-border text-light mt-5" role="status">

</div>}

                      
                      {error && (
                         <div class="alert alert-danger mt-5 " role="alert">
                        {error}
                       </div>
                   
                  )}
                      {successMessage && (
                         <div class="alert alert-success mt-5 success-message" role="alert">
                        {successMessage}
                       </div>
                   
                  )}
                    
                    </form>
						    </div>
				      </div>
				    </div>
				  </div>
				</div>
		</section>
   
    )       
}

export default RegistrationForm




