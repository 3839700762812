import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Footer_v1 = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const $ = window.$;

        let publicUrl = process.env.PUBLIC_URL + '/';
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/theme.js";

        document.body.appendChild(minscript);

        $('.go-top').find('a').on('click', function () {
            $(window).scrollTop(0);
        });
    }, []);

    let publicUrl = process.env.PUBLIC_URL + '/';
    let imgattr = "Footer logo";

    return (
        <div>
            <footer className="site-footer-one">
                <div className="site-footer-one__upper">
                    <div className="container">
                        <a href="https://vallhebron.com" target="_blank">
                            <img src={publicUrl + "assets/images/brand/logo_valldhebron-blanco.png"} alt={imgattr} className="site-footer-one__upper-moc" />
                        </a>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="footer-widget footer-widget__about">
                                    <a href="index-2.html">
                                        <h1 className="text-light">SIMOONS</h1>
                                    </a>
                                    <p>{t('footer.text')}</p>
                                    <p><a className='text-light' href="mailto:info@simoonsvirtual.com">info@simoonsvirtual.com</a></p>
                                    <div className="footer-widget__social d-none">
                                        <a href="https://www.facebook.com/webtend/"><i className="fab fa-facebook-f" /></a>
                                        <a href="https://www.twitter.com/webtend/"><i className="fab fa-twitter" /></a>
                                        <a href="https://google.com/webtend"><i className="fab fa-google-plus-g" /></a>
                                        <a href="https://behance.com/webtend"><i className="fab fa-behance" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="footer-widget footer-widget__contact d-none">
                                    <h3 className="footer-widget__title">Información</h3>
                                    <div className="footer-widget__contact-box">
                                        <div className="footer-widget__contact-top">
                                            <i className="far fa-map-marked" />
                                            <h3>Dirección</h3>
                                        </div>
                                        <p>Barcelona</p>
                                    </div>
                                    <div className="footer-widget__contact-box">
                                        <div className="footer-widget__contact-top">
                                            <i className="far fa-phone" />
                                            <h3>Teléfono</h3>
                                        </div>
                                        <p><a href="tel:+82-123-456-89">+82 123 456 89</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-footer-one__bottom">
                    <div className="container">
                        <div className="site-footer-one__bottom-line" />
                        <p>Copy@2023 <a href="#">SIMOONS</a>. All Right Reserved. Design By Oasis. </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer_v1;
