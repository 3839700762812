import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-color';
import Service from './section-components/service-details-01';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

const ServiceV1 = () => {
    let { id } = useParams();
    const { i18n } = useTranslation();

    const translationFileName = i18n.language === 'es' ? 'es.json' : 'en.json';
    const Escenarios = require(`../translations/${translationFileName}`);
    const result = Object.values(Escenarios.enviroments).find(env => env.id == id);
    const [escenarios, setEscenarios] = React.useState(result);

    useEffect(() => {
        const fetchTranslations = async () => {
            const newTranslationFileName = i18n.language === 'es' ? 'es.json' : i18n.language === 'ca' ? 'ca.json' : 'en.json';
            const newEscenarios = require(`../translations/${newTranslationFileName}`);
            const newResult = Object.values(newEscenarios.enviroments).find(env => env.id == id);
            setEscenarios(newResult);
        };
        fetchTranslations();
    }, [i18n.language, id]);

    return (
        <div>
            <Navbar />
            <Service headertitle={escenarios.title} escenarios={escenarios} id={id} />
            <Footer />
        </div>
    );
}

export default ServiceV1;
