import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
	const { t } = useTranslation();

    return <div>
		  	<section className="contact-two">
		    	<div className="container">
					<div className="row">
						<div className="col-lg-12 d-flex justify-content-center">
							<div className="my-auto">
								<div className="service-two__block">
									<div className="block-title-two text-center pt-5">
										<h3 className="pt-5 pb-3">{t('contact.title')}</h3>
									</div>
								</div>
							</div>
						</div>
	  				</div>
		      		<div className="row">
		        <div className="col-lg-5">
		          <div className="contact-two__content">
		            <div className="contact-two__block">
		             
		              <p className='pt-5 pr-5'>{t('contact.subtitle')}</p>
		            </div>{/* /.contact-two__block */}
		            <ul className="list-unstyled contact-two__info-list d-none">
		              <li>
		                <div className="contact-two__info-list-title">
		                  <i className="far fa-map-marked" />
		                  Dirección:  
		                </div>{/* /.contact-two__info-list-title */}
		                <p>Dirección Barcelona</p>
		              </li>
		              <li>
		                <div className="contact-two__info-list-title">
		                  <i className="far fa-phone" />
		                  Teléfono :
		                </div>{/* /.contact-two__info-list-title */}
		                <p><a href="tel:08964712365">123456789</a></p>
		              </li>
		              <li>
		                <div className="contact-two__info-list-title">
		                  <i className="far fa-envelope" />
		                  Email :
		                </div>{/* /.contact-two__info-list-title */}
		                <p><a href="mailto:support@gmail.com">email@gmail.com</a></p>
		              </li>
		            </ul>{/* /.contact-two__info-list */}
		          </div>{/* /.contact-two__content */}
		        </div>{/* /.col-lg-5 */}
		        <div className="col-lg-7 pt-5">
		          <form action="#" className="contact-two__form">
		           {/*  <div className="contact-two__block">
		              <h3>Get In Touch</h3>
		              <p>Sed ut perspiciatis unde omnis iste natus erro voluptat accusantium <br />doloremque laudantium
		                totam rem aperiam eaque</p>
		            </div>/.contact-two__block */}
		            <div className="row">
		              <div className="col-md-6 ">
		                <input type="text" placeholder={t('contact.form.name')} />
		              </div>{/* /.col-md-6 */}
		              <div className="col-md-6">
		                <input type="text" placeholder="Email " />
		              </div>{/* /.col-md-6 */}
		              <div className="col-md-12">
		                <input type="text" placeholder={t('contact.form.subject')} />
		              </div>{/* /.col-md-12 */}
		              <div className="col-md-12">
		                <textarea name="message" placeholder={t('contact.form.men')} defaultValue={""} />
						</div>{/* /.col-md-12 */}
						<div className="col-md-12">
						<label> <input type="checkbox"/> {t('contact.form.cookies')} <a href="https://www.vallhebron.com/es/politica-de-proteccion-de-datos">{t('contact.form.cookiesbutton')}</a></label>
		                </div>
						<div className="col-md-12">
						<button type="submit" className="thm-btn contact-two__btn">{t('contact.form.button')} <i className="fa fa-angle-double-right" /></button>{/* /.thm-btn contact-two__btn */}
						</div>{/* /.col-md-12 */}
		            </div>{/* /.row */}
		          </form>{/* /.contact-two__form */}
		        </div>{/* /.col-lg-7 */}
		      </div>{/* /.row */}
		    </div>{/* /.container */} 
		  </section>{/* /.contact-two */}
		  
		</div>

        
}

export default Contact