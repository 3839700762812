import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-color';
import Posts from './blog-components/blog-grid';
import Footer from './global-components/footer';
import Blog from '../json/posts.json';
import { useParams } from 'react-router-dom';

const BlogGrid = () => {
  const [posts, setPosts] = React.useState([]);
  const { id } = useParams();

  useEffect(() => {
    const categoryId = parseInt(id)
    // Filtra los posts con el ID correspondiente en Blog JSON
    const selectedPosts = Blog.filter(post => post.categories.includes(categoryId) ? post : null)
    setPosts(selectedPosts);
  }, [id]);

 

  return (
    <div>
      <Navbar />
      
      <Posts key={posts.map(post => post.id).join(',')} posts={posts} />
      <Footer />
    </div>
  );
}

export default BlogGrid;
 