import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TestimonialV2 = (props) => {
	let publicUrl = process.env.PUBLIC_URL+'/'
	let imagealt = 'image'
	const { t } = useTranslation();
    let CustomClass = props.CustomClass ? props.CustomClass : ''
// Declaramos un estado para guardar el dato
const [data, setData] = useState(null);

useEffect(() => {
  const fetchData = async () => {
	try {
	  const response = await fetch('https://admin.simoonsvirtual.cat/api/count/users');
	  if (!response.ok) {
		throw new Error(`Error: ${response.status}`);
	  }
	  const result = await response.json();
	  setData(result);
	} catch (error) {
	  console.error('Error al traer resultados:', error);
	}
  };

  fetchData();
}, []); 


    return  <section className={"testimonials-two "+CustomClass}>
			  <div className="container">
			    <div className="block-title-two text-center title-testimonial">
			      
			      <h3>{t('home.opinionsHeader.title')}</h3>
			    </div>{/* /.block-title-two */}

				<div className='row pb-5'>
					<div className='col-12 text-center'><h3 className="amount-testimonial">+{data} {t('home.opinionsHeader.subtitle')}</h3></div>
					

				</div>

			    <div className="testimonials-two__carousel owl-carousel thm__owl-carousel owl-theme" data-options="{&quot;loop&quot;: true, &quot;margin&quot;: 30, &quot;stagePadding&quot;: 0, &quot;items&quot;: 3, &quot;smartSpeed&quot;: 700, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;nav&quot;: false, &quot;dots&quot;: true, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 0 }, &quot;1199&quot;: { &quot;items&quot;: 2 }, &quot;1200&quot;: { &quot;items&quot;: 3 } }}">
			      <div className="item">
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			           
			            <div className="testimonials-two__top-content">
			              <h3>{t('home.opinions.title')}</h3>
			              
			            </div>{/* /.testimonials-two__top-content */}
			          </div>{/* /.testimonials-two__top */}
			          <div className="testimonials-two__content">
			            <p>{t('home.opinions.description')}</p>
			          </div>{/* /.testimonials-two__content */}
			          <div className="testimonials-two__bottom">
			            
			            <div className="testimonials-two__stars">
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			            </div>{/* /.testimonials-two__stars */}
			          </div>{/* /.testimonials-two__bottom */}
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}
			      <div className="item">
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            
			            <div className="testimonials-two__top-content">
			              <h3>{t('home.opinions2.title')}</h3>
			              
			            </div>{/* /.testimonials-two__top-content */}
			          </div>{/* /.testimonials-two__top */}
			          <div className="testimonials-two__content">
			            <p>{t('home.opinions2.description')}</p>
			          </div>{/* /.testimonials-two__content */}
			          <div className="testimonials-two__bottom">
			           
			            <div className="testimonials-two__stars">
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			            </div>{/* /.testimonials-two__stars */}
			          </div>{/* /.testimonials-two__bottom */}
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}
			      <div className="item">
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            
			            <div className="testimonials-two__top-content">
			              <h3>{t('home.opinions3.title')}</h3>
			              
			            </div>{/* /.testimonials-two__top-content */}
			          </div>{/* /.testimonials-two__top */}
			          <div className="testimonials-two__content">
			            <p>{t('home.opinions3.description')}</p>
			          </div>{/* /.testimonials-two__content */}
			          <div className="testimonials-two__bottom">
			            <div className="testimonials-two__stars">
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			            </div>{/* /.testimonials-two__stars */}
			          </div>{/* /.testimonials-two__bottom */}
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}
				  <div className="item">
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            
			            <div className="testimonials-two__top-content">
			              <h3>{t('home.opinions4.title')}</h3>
			              
			            </div>{/* /.testimonials-two__top-content */}
			          </div>{/* /.testimonials-two__top */}
			          <div className="testimonials-two__content">
			            <p>{t('home.opinions4.description')}</p>
			          </div>{/* /.testimonials-two__content */}
			          <div className="testimonials-two__bottom">
			            <div className="testimonials-two__stars">
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			              <i className="far fa-star" />
			            </div>{/* /.testimonials-two__stars */}
			          </div>{/* /.testimonials-two__bottom */}
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}



	
			    </div>{/* /.testimonials-two__carousel owl-carousel thm__owl-carousel owl-theme */}
			  </div>{/* /.container */}
			</section>

			 
        
}

export default TestimonialV2