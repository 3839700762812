import React from 'react';
import { useTranslation } from 'react-i18next';


  const CtaV8 = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    const { t } = useTranslation();
    return <section className="cta-five  go-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 d-flex">
                    <div className="my-auto">
                      <div className="cta-five__content">
                        <h3>{t('home.section1.title')}</h3>
                        <p>{t('home.section1.subtitle')}</p>
                       
                      </div>{/* /.cta-five__content */}
                    </div>{/* /.my-auto */}
                  </div>{/* /.col-lg-5 */}
                  
                  <div className="col-lg-6">
                    <div className="cta-five__video cta-video-mobile m-lg-4 ">
                    <img src={publicUrl+"assets/videos/mapa.gif"} alt="Descripción del GIF" width="100%" className='pt-5 pb-5'/>

                   
                    </div>{/* /.cta-five__image */}
                  </div>{/* /.col-lg-7 */}
                  
                </div>{/* /.row */}
              </div>{/* /.container */}
            </section>

        
	}

export default CtaV8