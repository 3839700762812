import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';



 
const BlogGrid = (props) => {

	const { t } = useTranslation();
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

	  //creating an array of JSX for each post, using the map array method
	  const postsJSX = props.posts.map((post) => (
		
			   <div className="col-lg-4 col-md-6 summary" key={post.id} onClick={() => `/blog-details/${post.id}`} >
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={`${publicUrl}assets/images/blog/${post.img}`} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              
			            </div>{/* /.blog-one__author */}
						
			            <h3><Link to={`/blog-details/${post.id}`}>{post.title}</Link></h3>
						
			            <Link to={`/blog-details/${post.id}`} className="thm-btn blog-one__btn">Leer más <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>
		 

		
	  ));


    return (
     

<section className="service-two">
<div className="container  go-top pt-5 mt-5">
  <div className="row">  
	<div className="col-lg-12 d-flex justify-content-center">
	  <div className="my-auto">
		<div className="service-two__block">
		  <div className="block-title-two text-center">   
			<h3 className="pb-3">{t('blog.title')}</h3>	
		  </div>
		</div>
	  </div>
	</div>
  </div>

	<div className="row">
		<div className="col-lg-12">
			<div className="posts row">
	  			{postsJSX} 
			</div>
	  	</div>
	</div>
  </div>
</section>





    )
  
}

export default BlogGrid;
