import React, { Component } from 'react';
import Comments from './comments';
import Sidebar from './sidebar';

class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
	

    return (
     <section className="blog-list blog-details">
		  <div className="container">
		    <div className="row post">
		      <div className="col-lg-12">
		        <div className="blog-one__single">
		          <div className="blog-one__image">
		            <img className='img-blog' src={publicUrl+`assets/images/blog/${this.props.post.img}`}  alt={ imagealt } height='450' width='770'/>
		          </div>{/* /.blog-one__image */}
		          <div className="blog-one__content">
		            <div className="blog-one__top  flex-row-reverse">
		             
		              <div className="blog-one__social">
		                <span>Compartir</span>
		                <b><i className="far fa-share-alt" /></b>
		                <a href="#"><i className="fab fa-facebook-f" /></a>
		                <a href="#"><i className="fab fa-twitter" /></a>
		                <a href="#"><i className="fab fa-linkedin-in" /></a>
		              </div>{/* /.blog-one__social */}
		            </div>{/* /.blog-one__top */}
		            <h3>{this.props.post.title}</h3>
		            <p>{this.props.post.body}</p>
		          
		          </div>{/* /.blog-one__content */}
		         
		        </div>{/* /.blog-one__single */}
		        
		       
		      </div>{/* /.col-lg-8 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

    )
  }
}

export default BlogDetails;
