import React from 'react';
import Navbar from './global-components/navbar';

import Footer from './global-components/footer';
import Registerform from './section-components/register-form';
import Banner from './section-components/banner';



const Register = () => {
    return <div>
        <Navbar />
       <Registerform/>
        <Footer />
    </div>
}

export default Register

