import React from 'react';
import Navbar from './global-components/navbar-color';
import Service from './section-components/service';
import Footer from './global-components/footer';


const ServiceV1 = () => {

    return <div>
        <Navbar />
        <Service />
        <Footer />
    </div>
}

export default ServiceV1

