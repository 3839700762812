import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutStyleOne extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="about-one  go-top">
			  <div className="container">
			    <div className="row justify-content-end">
			      <div className="col-lg-12">
			        <div className="about-one__content">
			          <div className="block-title text-center">
			            <p><span>SIMOONS</span></p>
			            <h3>¿Que es? </h3>
			            <div className="block-title__line" />{/* /.block-title__line */}
			          </div>{/* /.block-title */}
			          <p>SIMOONS (Serious Game para la formaciónonline con simulación en salud) es un serious game dirigido a profesionales del ámbito sanitario. Permite formar a profesionales a través de experiencias de simulación virtual en las que se interactúa con el usuario y se le plantean desafíos. De esta manera se facilita el entrenamiento y la mejora de la preparación del equipo asistencial para atender de manera segura y eficaz a los pacientes.
</p>
			          
			          <p>En Vall d’Hebron Centro de Simulación Clínica Avanzada-Dirección de Docencia somos un referente en el campo de la simulación gracias a la innovación en programas de entrenamiento en simulación virtual. Con SIMOONS apostamos por promover el aprendizaje utilizando</p>
  {/* /.thm-btn */}
			        </div>{/* /.about-one__content */}
			      </div>{/* /.col-lg-6 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

        }
}

export default AboutStyleOne