import React from 'react';
import { useTranslation } from 'react-i18next';

const FaqV3 = () => {

  const { t } = useTranslation();

    return  <section className="faq-one faq-one__faq-page">
          <div className="container">


          <div className="row">
	  
    <div className="col-lg-12 d-flex justify-content-center">
      <div className="my-auto">
      <div className="service-two__block">
        <div className="block-title-two text-center">
        <h3 className="pt-5 pb-5">{t('faq.title')}</h3>
        </div>{/* /.block-title-two */}
        
        {/* /.thm-btn */}
      </div>{/* /.service-two__block */}
      </div>{/* /.my-auto */}
    </div>{/* /.col-lg-5 */}
  
    </div>{/* /.row */}
  
            <div className="row">
              <div className="col-lg-8 pr-5">
                <div className="faq-one__block">
                 
                  <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                   
                    <div className="accrodion">
                      <div className="accrodion-title">
                        <h4>{t('faq.question1.title')}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>{t('faq.question1.description')}</p>
                        </div>{/* /.inner */}
                      </div>
                    </div>
                    <div className="accrodion">
                      <div className="accrodion-title">
                        <h4>{t('faq.question2.title')}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>{t('faq.question2.description')}</p>
                        </div>{/* /.inner */}
                      </div>
                    </div>

                    <div className="accrodion">
                      <div className="accrodion-title">
                        <h4>{t('faq.question3.title')}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>{t('faq.question3.description')}</p>
                        </div>{/* /.inner */}
                      </div>
                    </div>

                    <div className="accrodion">
                      <div className="accrodion-title">
                        <h4>{t('faq.question4.title')}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>{t('faq.question4.description')}</p>
                        </div>{/* /.inner */}
                      </div>
                    </div>

                    <div className="accrodion">
                      <div className="accrodion-title">
                        <h4>{t('faq.question5.title')}</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>{t('faq.question5.description')}</p>
                        </div>{/* /.inner */}
                      </div>
                    </div>

                  </div>
                </div>{/* /.faq-one__block */}
                
              </div>{/* /.col-lg-8 */}
              <div className="col-lg-4">
                <div className="faq-one__form-wrap">
                  <form action="#" className="contact-one__form">
                    <h3>{t('faq.form.title')}</h3>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="contact-one__input-group">
                          <i className="contact-one__input-icon far fa-user" />
                          <input type="text" placeholder={t('faq.form.name')} />
                        </div>{/* /.contact-one__input-group */}
                      </div>{/* /.col-lg-6 */}
                      <div className="col-lg-12">
                        <div className="contact-one__input-group">
                          <i className="contact-one__input-icon far fa-envelope" />
                          <input type="text" placeholder="Email " />
                        </div>{/* /.contact-one__input-group */}
                      </div>{/* /.col-lg-6 */}
                    
                      <div className="col-lg-12">
                        <div className="contact-one__input-group">
                          <i className="contact-one__input-icon far fa-phone" />
                          <input type="text" placeholder={t('faq.form.tel')} />
                        </div>{/* /.contact-one__input-group */}
                      </div>{/* /.col-lg-6 */}
                      <div className="col-lg-12">
                        <div className="contact-one__input-group">
                          <i className="contact-one__input-icon far fa-pencil-alt" />
                          <textarea placeholder={t('faq.form.men')} defaultValue={""} />
                        </div>{/* /.contact-one__input-group */}
                      </div>{/* /.col-lg-12 */}
                      <div className="col-lg-12">
                        <button type="submit" className="thm-btn contact-one__form-btn">{t('faq.form.button')} <i className="fa fa-angle-double-right" /></button>
                        {/* /.thm-btn contact-one__form-btn */}
                      </div>{/* /.col-lg-12 */}
                    </div>{/* /.row */}
                  </form>{/* /.contact-one__form */}
                </div>{/* /.faq-one__form-wrap */}
              </div>{/* /.col-lg-4 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>


        
}

export default FaqV3