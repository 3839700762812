import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class WhyChooseUs extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="service-one go-top">
          <div className="container">
           
            <div className="row high-gutter  go-top">



              <div className="col-lg-3">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img width="90px" src={publicUrl+"assets/images/shapes/icono-multijugador.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3><Link to="/service-details">Multijugador en línea</Link></h3>
                     
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Facilita y enriquece la labor del docente y del alumno ya que permite reunir a facilitadores, expertos y alumnos de cualquier lugar para realizar prácticas basadas en simulación.</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}



              <div className="col-lg-3">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img width="90px" src={publicUrl+"assets/images/shapes/icono-simulador.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3><Link to="/service-details">Simulación de casos</Link></h3>
                      
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Permite adquirir competencias y desarrollar capacidades mediante la interacción. Este entorno interactivo nos acerca a la realidad y posibilita una experiencia práctica reflexiva.</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}


              <div className="col-lg-3">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img width="90px" src={publicUrl+"assets/images/shapes/icono-adaptable.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3><Link to="/service-details">Adaptable</Link></h3>
                      
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>La adaptabilidad al entorno favorece que emerjan nuevas necesidades educativas que fomentan la creación y el diseño de nuevos serious games en la plataforma SIMOONS.</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}


              <div className="col-lg-3">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img width="90px" src={publicUrl+"assets/images/shapes/icono-escalable.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3><Link to="/service-details">Escalable</Link></h3>
                     
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>La tecnología con la que se ha creado SIMOONS nos permite llegar a todas partes. Creemos que la escalabilidad es un factor muy importante para el proyecto.</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>

        }
}

export default WhyChooseUs