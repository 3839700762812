import React from 'react';
import { useTranslation } from 'react-i18next';


const FunfactV5 = () => {
    const { t } = useTranslation();
    return <section className="funfact-one funfact-one__service-page">
			  <div className="container-fluid">
			    {/**<div className="inner-container wow fadeInUp" data-wow-duration="1500ms">*/}
			      <div className="row cta-portada text-center pt-5 pb-4">
			     
			        <div className="col-lg-12 col-md-12 col-12">
			          <div className="funfact-one__single">
			            
			            <h3 className='text-light cta-titulo'>{t('home.banner.title')}</h3>
			            <p className='text-light cta-descripcion'>{t('home.banner.subtitle')}</p> 
						<div className="cta-four__btn-block ">
                          <a href="/service/" className=" btn-cta-portada">{t('home.banner.button')}</a>
                         
                        </div>{/* /.cta-four__btn-block */}
			          </div>{/* /.funfact-one__single */}
			        </div>{/* /.col-lg-3 col-md-6 */}
			        
			      </div>{/* /.row */}
			    </div>{/* /.inner-container */}
			  {/*</div> /.container */}
			</section>


        
}

export default FunfactV5