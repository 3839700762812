import React from 'react';
import Navbar from './global-components/navbar-color';
import PageHeader from './global-components/page-header';
import Posts from './blog-components/blog-grid';
import Footer from './global-components/footer';
import BlogCat from '../json/postscategories.json';
import Categoriesgrid from './section-components/categories-grid';



const Categories = () => {


//Post to track the post displayed by Post
const [post, setPost] = React.useState(BlogCat[0])
  
//Function to change the displayed post
const selectPost = (selected) => {
  setPost(selected)
}

  return <div>
      <Navbar />
     
      <Categoriesgrid  posts={BlogCat} select={selectPost}/>
      <Footer />
  </div>
}

export default Categories

