import React from 'react';
import { useTranslation } from 'react-i18next';

const CtaV14 = () => {
  let publicUrl = process.env.PUBLIC_URL+'/'
  const { t } = useTranslation();
    return <section className="cta-five  go-top">
              <div className="container">
                <div className="row pt-5">
                  <div className="col-lg-6 d-flex">
                    <div className="my-auto">
                      <div className="cta-five__content">
                        <h3>{t('home.section3.title')}</h3>
                        <p>{t('home.section3.subtitle')}</p>
                       
                      </div>{/* /.cta-five__content */}
                    </div>{/* /.my-auto */}
                  </div>{/* /.col-lg-5 */}
                 
                  <div className="col-lg-6">
                    <div className="cta-five__video cta-video-mobile-3 ">
                    <video width="100%" height="500"  loop autoPlay muted>
      <source src={publicUrl+"assets/videos/video_portada.mp4"} type="video/mp4"/>
</video>
                    </div>{/* /.cta-five__image */}
                  </div>{/* /.col-lg-7 */}
                  
                </div>{/* /.row */}
              </div>{/* /.container */}
            </section>

        
	}

export default CtaV14