import React, {  useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';


function Banner() {
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const history = useHistory()
	const { t } = useTranslation();



	const handleInputChange = (e) => {
		const {id , value} = e.target;
		if(id === "email"){
			setEmail(value);
		}
	}

	const handleSubmit  = (e) => {
		e.preventDefault();
		if (!email) {
			setError('Ingrese su correo electrónico');
			return;
		}
	  
		if (!/\S+@\S+\.\S+/.test(email)) {
			setError('Ingrese un correo electrónico válido');
			return;
		}
		const state = {emailRegister:email}
		history.push({pathname: '/register', search: '', hash: '', state});
	}	
		

    return <section className="banner-one go-top">	   
			<div className='overlay'>
				<div className="container-fluid">
				    <div className="row">
				      <div className="col-lg-12 text-center">
				        <div className="banner-one__content">
				          <h3>{t('home.title')}<br/> {t('home.title2')}</h3>
				          <p>{t('home.subtitle')}</p>
						  <form  className="form_portada"  onSubmit={handleSubmit}>
		                    <div className="row">
								<div className="col-md-4 offset-md-3 col-8 text-right">
					  				<input  type="email" id="email" className="form__input" value={email|| ''} onChange = {(e) => handleInputChange(e)} placeholder="Email" required/>
		              			</div>{/* /.col-md-6 */}
		              			<div className="col-md-3 col-4 text-left bloquebutton">
		                		<button type="submit" className="thm-btn "> {t('home.button')} <i className="fa fa-angle-double-right" /></button>{/* /.thm-btn contact-two__btn */}
		              		</div>{/* /.col-md-12 */}
		           			</div>{/* /.row */}
							   {error && <p>{error}</p>}
		          		  </form>{/* /.contact-two__form */}
						</div>
				      </div>
				    </div>
				  </div>
				  </div>
				</section>
        
}

export default Banner